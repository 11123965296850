<template>
  <div>
    <session-notices />
    <session-redirect-message />
    <content-background-cover
      id='page-background'
      :custom-css='pageBackgroundCustomCss'
      background-color='#FFF'
      class='z-0' />
    <div class='py-2 lg:py-8 relative'>
      <content-background-cover
        id='video-background'
        :custom-css='videoBackgroundCustomCss'
        background-color='#F3F4F6'
        class='z-0'
        :top-offset='0' />
      <h1 v-if='showSessionTitleTop' 
        class='text-lg lg:text-2xl font-semibold mb-4 px-2 lg:px-0'>
        {{ selectedSession.title }}
        <span v-if='selectedSession.subtitle'>- {{ selectedSession.subtitle }}</span>
      </h1>
      <div v-if='showContentSelect' 
        class='text-right flex flex-row items-center pb-2 px-2 lg:px-0'
        :class='sessionContentSelectBoxClass'>
        <session-content-select v-if='showContentSelect' />
      </div>
      <div ref='sessionContentContainer'
        class='flex flex-col lg:flex-row lg:gap-x-4 lg:items-stretch lg:justify-center relative'>
        <component :is='selectedVodVideoPlayerComponent'
          v-if='hasSelectedSessionCurrentContent'
          :key='selectedSessionId'
          :showing-content='selectedSessionCurrentContent'
          :autoplay='false'
          :mute='false'
          :loop='false'
          class='flex-shrink-0 z-10 relative'
          :style='`width: ${sessionContentContainerWidth}px; height: ${sessionContentContainerHeight}px;`' />
        <session-question v-if='showQuestionsNextToVideo'
          class='lg:flex-grow mt-2 lg:mt-0 z-10 lg:rounded-lg'
          :style='`height: ${sessionContentContainerHeight}px`' />
      </div>
    </div>
    <div v-if='!isFullscreenVideo' class='pb-64'>
      <session-sponsors-carousel v-if='selectedSessionCarouselSponsors.length'
        class='px-2 lg:px-0 mt-2' />
      <session-details-short v-if='showSessionDetailsShort'
        class='mt-16 px-2 lg:px-0' />
      <session-details-full v-if='showSessionDetailsFull'
        class='mt-16 px-2 lg:px-0' :session='sessionDetails' />
      <session-detail-topics v-if='showSessionTopics'
        class='mt-16 px-2 lg:px-0' :session='sessionDetails' />
      <session-speakers v-if='showSessionSpeakers'
        class='mt-16 px-2 lg:px-0' />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import SessionRedirectMessage  from '@/components/sessions/SessionRedirectMessage.vue'
import SessionNotices          from '@/components/sessions/SessionNotices.vue'
import SessionQuestion         from '@/components/sessions/SessionQuestion.vue'
import SessionSpeakers         from '@/components/sessions/SessionSpeakers.vue'
import SessionSponsorsCarousel from '@/components/sessions/SessionSponsorsCarousel.vue'
import SessionDetailsShort     from '@/components/sessions/SessionDetailsShort.vue'
import SessionDetailsFull      from '@/components/sessions/SessionDetailsFull.vue'
import SessionDetailTopics     from '@/components/sessions/SessionDetailTopics.vue'
import SessionContentSelect    from '@/components/sessions/SessionContentSelect.vue'
import DateTimeStringHelpers   from '@/utils/date-time-string-helpers'

const VodContentVimeo = function() {
  return import('@/components/contents/VodContentVimeo')
}
const VodContentWecandeo = function() {
  return import('@/components/contents/VodContentWecandeo')
}

export default {
  name: 'VodSession',
  props: {
    showSessionContentSelect: {
      default: true,
      type: Boolean
    },
    showSessionSpeakers: {
      default: false,
      type: Boolean
    },
    showSessionTitleTop: {
      default: false,
      type: Boolean
    },
    showSessionDetailsFull: {
      default: true,
      type: Boolean
    },
    showSessionDetailsShort: {
      default: false,
      type: Boolean
    },
    showSessionTopics: {
      default: false,
      type: Boolean
    },
    showSessionQuestionsBox: {
      default: false,
      type: Boolean
    },
    // need to fix with event config
    pageBackgroundCustomCss: {
      default: '',
      type: String
    },
    videoBackgroundCustomCss: {
      default: '',
      type: String
    }
  },
  watch: {
    'selectedSessionId': {
      handler: function (newVal) {
        if (newVal && (this.showSessionDetailsFull || this.showSessionTopics)) {
          this.getSingleSessionDetails(this.selectedSessionId)
        }
      },
      immediate: true
    },
    isFullscreenVideo: { // needed for PCs since the trigger for leaving fullscreen video is later
      handler: function (newVal) {
        if (!newVal) {
          this.getContainerWidth()
        }
      },
    },
  },
  data () {
    return {
      sessionContentContainerWidth: 0,
    }
  },
  components: {
    SessionRedirectMessage,
    SessionNotices,
    SessionQuestion,
    SessionSponsorsCarousel,
    SessionDetailsShort,
    SessionDetailTopics,
    SessionSpeakers,
    VodContentVimeo,
    VodContentWecandeo,
    SessionDetailsFull,
    ContentBackgroundCover,
    SessionContentSelect,
  },
  computed: {
    ...mapState([
      'isFullscreenVideo',
    ]),
    ...mapState('sessions', [
      'sessionDetails',
      'selectedSession',
    ]),
    ...mapGetters('sessions', [
      'hasManySelectedSessionListOfContents',
      'selectedSessionCurrentContent',
      'hasSelectedSessionCurrentContent',
      'selectedSessionId',
      'selectedSessionCarouselSponsors',
      'selectedSessionConfigDefaultVodVideoPlayer',
    ]),
    sessionContentSelectBoxClass () {
      return this.showContentSelect ? 'justify-between' : 'justify-end'
    },
    sessionContentContainerHeight () {
      return (this.sessionContentContainerWidth && this.selectedSessionCurrentContent.height && this.selectedSessionCurrentContent.width)
             ? Math.max(parseInt((this.sessionContentContainerWidth) * (this.selectedSessionCurrentContent.height / this.selectedSessionCurrentContent.width)), 360)
             : this.sessionContentContainerWidth * (9/16) // assuming this is the standard vimeo aspect ratio
    },
    showContentSelect () {
      return this.showSessionContentSelect && this.hasManySelectedSessionListOfContents
    },
    showQuestionsNextToVideo () {
      return !this.isFullscreenVideo && this.showSessionQuestionsBox
    },
    selectedVodVideoPlayerVimeo () {
      return this.selectedSessionConfigDefaultVodVideoPlayer === 'vimeo'
    },
    selectedVodVideoPlayerWecandeo () {
      return this.selectedSessionConfigDefaultVodVideoPlayer === 'wecandeo'
    },
    selectedVodVideoPlayerComponent () {
      if (this.selectedVodVideoPlayerVimeo) {
        return 'vod-content-vimeo'
      } else if (this.selectedVodVideoPlayerWecandeo) {
        return 'vod-content-wecandeo'
      } else {
        return ''
      }
    },
    sessionScheduledStartDate () {
      return DateTimeStringHelpers.toDateWithDay(this.selectedSession.scheduledStartTime)
    },
    sessionScheduledStartTime () {
      return DateTimeStringHelpers.toTime(this.selectedSession.scheduledStartTime)
    },
    sessionScheduledEndTime () {
      return DateTimeStringHelpers.toTime(this.selectedSession.scheduledEndTime)
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSingleSessionDetails',
    ]),
    getContainerWidth () {
      if (this.$refs.sessionContentContainer.clientWidth >= 1024) {
        this.sessionContentContainerWidth = (this.showQuestionsNextToVideo) ? this.$refs.sessionContentContainer.clientWidth * 0.7 : this.$refs.sessionContentContainer.clientWidth
      } else {
        this.sessionContentContainerWidth = this.$refs.sessionContentContainer.clientWidth
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getContainerWidth)
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getContainerWidth)
      this.getContainerWidth()
    })
  }
}
</script>

<style lang='scss' scoped>
  .vod-content-player {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
</style>

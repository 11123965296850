<template>
  <div>
    <div class='session-title'>
      {{ selectedSession.title }}
    </div>
    <div class='text-lg font-normal text-gray-600 mb-4'>
      {{ selectedSession.subtitle }}
    </div>
    <div class='text-base lg:text-lg font-semibold uppercase'>
      {{ sessionScheduledStartDate }}
    </div>
    <div class='text-base'>
      {{ sessionScheduledStartTime }} - {{ sessionScheduledEndTime }}
    </div>
    <div class='text-base uppercase mb-2'>
      {{ sessionLocationString }}
    </div>
    <p class='whitespace-pre-line'>
      {{ selectedSession.description }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DateTimeStringHelpers   from '@/utils/date-time-string-helpers'


export default {
  name: 'SessionDetailsShort',
  computed: {
    ...mapState('sessions', [
      'selectedSession'
    ]),
    sessionScheduledStartDate () {
      return DateTimeStringHelpers.toDateWithDay(this.selectedSession.scheduledStartTime)
    },
    sessionScheduledStartTime () {
      return DateTimeStringHelpers.toTime(this.selectedSession.scheduledStartTime)
    },
    sessionScheduledEndTime () {
      return DateTimeStringHelpers.toTime(this.selectedSession.scheduledEndTime)
    },
    sessionLocationString () {
      return this.selectedSession.locations ? this.selectedSession.locations.map(location => location.split('||')[0]).join(', ') : ''
    },
  },
}
</script>

<style type='scss' scoped>
.session-title {
  @apply text-gray-700 text-2xl font-semibold;
}
</style>

<template>
  <div class='flex flex-row items-center justify-end'>
    <el-popover
      placement='left'
      width="165"
      trigger="hover"
      content="Total watched time today, for credit.">    
    <div slot='reference' class='row-centered'>
      <info-circle-icon class='inline-block h-5'/>
      <span class='text-sm lg:text-base inline-block font-semibold mx-1 whitespace-nowrap'>{{watchTotalTimeString}}</span>
      <loader-icon v-if='isWatchingSession' 
      class='spinner inline-block h-4'/>
    </div>
    </el-popover>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { LoaderIcon, InfoCircleIcon }         from 'vue-tabler-icons'
import DateTimeStringHelpers  from '@/utils/date-time-string-helpers'
import dayjs from 'dayjs'

export default {
  name: 'SessionTodayWatchedTime',
  components: {
    LoaderIcon,
    InfoCircleIcon,
  },
  data () {
    return {
      incrementTotalWatchedTime: null,
      watchTotalTime: 0,
    }
  },
  watch: {
    'selectedSessionId': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.getWatchedTime().then(resp => this.watchTotalTime = resp.total_watched)
        }
      },
      immediate: true
    },
    'isWatchingSession': {
      handler: function (newVal) {
        if (newVal) {
          this.incrementTotalWatchedTime = setInterval(() => {
            if (dayjs(this.selectedSession.scheduledStartTime).format() <= dayjs().format()) {
  // we should update the variable name to include the units
              this.watchTotalTime++ 
            }
          }, 1000)
        } else {
          clearInterval(this.incrementTotalWatchedTime)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('sessions', [
      'selectedSession',
      'isWatchingSession',
    ]),
    ...mapGetters('sessions', [
      'selectedSessionId',
    ]),
    watchTotalTimeString () {
      return DateTimeStringHelpers.secondToHourAndMinute(this.watchTotalTime)
    },
  },
  methods: {
    ...mapActions('watchedVideos', [
      'getWatchedTime',
    ])
  },
  mounted () {
    this.getWatchedTime().then(resp => this.watchTotalTime = resp.total_watched)
  }
}
</script>

<style lang='scss' scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<template>
  <div>
    <div v-for='notice in activeNotices' 
      :key='`session-notice-${notice.id}`'
      :style='backgroundColor'
      class='flex flex-row-reverse items-center justify-center z-20 gap-x-4 py-2 px-4 border-b border-gray-400 shadow-md text-white'>
      <button
        class='opacity-75 hover:opacity-100 hover:shadow-md border border-gray-300 bg-white text-xs text-gray-900 pl-2 pr-3 py-2 rounded-md flex flex-row items-center gap-x-1'
        @click='dismissNotice(notice)'>
        <circle-x-icon />
        Close
      </button>
      <h5>{{notice.message}}</h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CircleXIcon } from 'vue-tabler-icons'

export default {
  name: 'SessionNotices',
  components: {
    CircleXIcon,
  },
  computed: {
    ...mapGetters('sessionNotices', [
      'activeNotices'
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor'
    ]),
    backgroundColor () {
      return `background-color: ${this.eventMainThemeColor};` 
    },
  },
  methods: {
    dismissNotice (notice) {
      this.$cookies.set(`webconcert_dismissednotice_${notice.id}`, 'true')
      notice.dismissed = true
    },
  },
}
</script>

<style lang='scss' scoped>
  button:hover + h5 {
    @apply line-through opacity-75 text-gray-700;
  }
</style>

<template>
  <div class='flex flex-row items-center justify-start gap-x-2 mr-4'>
    <label v-for='(contentOption, index) in selectedSessionListOfContents'
      :key='`content-list-select-${contentOption.id}`'
      class='flex flex-row justify-start items-center gap-x-1 text-sm text-gray-500 border border-blue-300 py-1 px-4 shadow-md lg:shadow-lg rounded'>
      <input type='radio'
             :value='index'
             v-model='selectedSessionCurrentContentIndex'
             class=''>
      <span>{{contentOption.data.contentLabel}}</span>
    </label>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
export default {
  name: 'SessionContentSelect',
  data () {
    return {
    }
  },
  computed: {
    ...mapFields('sessions',[
      'selectedSessionCurrentContentIndex',
    ]),
    ...mapGetters('sessions', [
      'selectedSessionListOfContents',
    ]),
  },
  mounted () {
    //reset selectedSessionCurrentContentIndex
    this.selectedSessionCurrentContentIndex = 0
  }
}
</script>

<template>
  <div>
    <div v-if='hasMessage'
      :style='backgroundColor'
      class='flex flex-row-reverse items-center justify-center z-20 gap-x-4 p-4 border-b border-gray-400 shadow-md text-white font-semibold'>
      <h5>{{redirectMessage}}</h5>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SessionRedirectMessage',
  computed: {
    ...mapState('sessions', [
      'redirectMessage'
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor'
    ]),
    hasMessage () {
      return this.redirectMessage
    },
    backgroundColor () {
      return `background-color: ${this.eventMainThemeColor};`
    },
  },
}
</script>


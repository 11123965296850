<template>
  <div class='sessions-sponsors-carousel mx-8 lg:px-0 px-4 h-28'>
    <vue-slick-carousel v-if='selectedSessionCarouselSponsors.length' 
      v-bind='settings'>
      <div v-for='(sponsor, index) in selectedSessionCarouselSponsors' :key='`sponsor-${index}`'>
        <div class='h-24 flex flex-col justify-center items-center'>
          <img 
            :src='sponsor.logoUrl' 
            class='p-4 block mx-auto max-h-24' 
            style='width: auto; max-width: 200px;'
            :class='hasLinkCssClass(sponsor.siteUrl)'
            @click='clickImage(sponsor.siteUrl)'>
          </div>
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import {mapGetters}     from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'SessionSponsorsCarousel',
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      settings: {
        'dots': false,
        'dotsClass': 'slick-dots custom-dot-class',
        'focusOnSelect': true,
        'infinite': true,
        'speed': 500,
        'autoplay': true,
        'adaptiveHeight': false,
        'autoplaySpeed': 10000,
        'slidesToShow': 6,
        'slidesToScroll': 2,
        'touchThreshold': 6,
        'responsive': [
          {
            'breakpoint': 1024,
            'settings': {
              'slidesToShow': 4,
              'slidesToScroll': 1
            }
          },
          {
            'breakpoint': 600,
            'settings': {
              'slidesToShow': 3,
              'slidesToScroll': 1
            }
          },
          {
            'breakpoint': 480,
            'settings': {
              'slidesToShow': 2,
              'slidesToScroll': 1
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('sessions', [
      'selectedSessionCarouselSponsors',
    ])
  },
  methods: {
    clickImage (url) {
      if (url) {
        if (url.indexOf('http') != -1) {
          window.open(url, '_blank')
        } else {
          window.open(`https://${url}`, '_blank')
        }
      } else {
        // do nothing
      }
    },
    hasLinkCssClass (url) {
      return (url) ? 'cursor-pointer' : ''
    },
  },
}
</script>

<style lang='scss'>

.sessions-sponsors-carousel .slick-prev:before {
    color: black;
  }

.sessions-sponsors-carousel .slick-next:before {
    color: black;
  }

.sessions-sponsors-carousel .slick-track {
    display: flex !important;
  }

.sessions-sponsors-carousel .slick-slide {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>

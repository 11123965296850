<template>
  <div>
    <div v-if='hasChairs'
      class='mt-12'>
      <h1 class='text-2xl font-semibold mb-4'>Chairs</h1>
      <div class='flex flex-row flex-wrap gap-4 justify-start'>
        <speaker-card v-for='speaker in selectedSessionChairs'
          :key='`speaker-chair-${speaker.id}`'
          :speaker='speaker' />
      </div>
    </div>
    <div v-if='hasModerators' 
      class='mt-12'>
      <h1 class='text-2xl font-semibold mb-4'>Moderators</h1>
      <div class='flex flex-row flex-wrap gap-4 justify-start'>
        <speaker-card v-for='speaker in selectedSessionModerators'
          :key='`speaker-moderator-${speaker.id}`'
          :speaker='speaker' />
      </div>
    </div>
    <div v-if='hasSpeakers' 
    class='mt-12'>
      <h1 class='text-2xl font-semibold mb-4'>Speakers</h1>
      <div class='flex flex-row flex-wrap gap-4 justify-start'>
        <speaker-card v-for='speaker in selectedSessionSpeakers'
          :key='`speaker-${speaker.id}`'
          :speaker='speaker' />
      </div>
    </div>
    <div v-if='hasPanelists' 
    class='mt-12'>
      <h1 class='text-2xl font-semibold mb-4'>Panelists</h1>
      <div class='flex flex-row flex-wrap gap-4 justify-start'>
        <speaker-card v-for='speaker in selectedSessionPanelists'
          :key='`speaker-${speaker.id}`'
          :speaker='speaker' />
      </div>
    </div>
    <div v-if='hasPanels' 
    class='mt-12'>
      <h1 class='text-2xl font-semibold mb-4'>Panels</h1>
      <div class='flex flex-row flex-wrap gap-4 justify-start'>
        <speaker-card v-for='speaker in selectedSessionPanels'
          :key='`speaker-${speaker.id}`'
          :speaker='speaker' />
      </div>
    </div>
    <div v-if='hasPresenters' 
    class='mt-12'>
      <h1 class='text-2xl font-semibold mb-4'>Presenters</h1>
      <div class='flex flex-row flex-wrap gap-4 justify-start'>
        <speaker-card v-for='speaker in selectedSessionPresenters'
          :key='`speaker-${speaker.id}`'
          :speaker='speaker' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import SpeakerCard from '@/components/speakers/SpeakerCard.vue'

export default {
  name: 'SessionSpeakers',
  components: {
    SpeakerCard,
  },
  watch: {
    selectedSessionId: {
      handler: function (newVal) {
        if (newVal) {
          this.getSessionSpeakers(newVal)
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState('sessions', [
    ]),
    ...mapGetters('sessions', [
      'selectedSessionId',
      'selectedSessionChairs',
      'selectedSessionModerators',
      'selectedSessionSpeakers',
      'selectedSessionPanelists',
      'selectedSessionPanels',
      'selectedSessionPresenters',
    ]),
    hasChairs () {
      return this.selectedSessionChairs.length > 0
    },
    hasModerators () {
      return this.selectedSessionModerators.length > 0
    },
    hasSpeakers () {
      return this.selectedSessionSpeakers.length > 0
    },
    hasPanelists () {
      return this.selectedSessionPanelists.length > 0
    },
    hasPanels () {
      return this.selectedSessionPanels.length > 0
    },
    hasPresenters () {
      return this.selectedSessionPresenters.length > 0
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSessionSpeakers',
    ]),
  }
}
</script>

<template>
  <div>
    <h3 class='text-gray-700 text-2xl font-semibold flex flex-row items-center gap-x-4'>
      Other Sessions 
      <button
        class='text-sm flex flex-row items-center gap-x-1 text-blue-400 uppercase bold border border-gray-100 pl-2 pr-1 py-1 rounded'
        @click='toggleShowSessions'>
        {{toggleButtonString}} ({{otherSessionsCount}})
        <component :is='showIcon' size='14' />
      </button>
    </h3>
    <div v-if='showOtherSessions'
      class='flex flex-col lg:flex-row flex-wrap justify-start mt-4 overflow-x-auto gap-2'>
      <div v-for='session in selectedSessionOtherSessions'
        :key='`otherSession-${session.id}`'
        class='w-full lg:w-72 cursor-pointer hover:shadow-md bg-gray-50 rounded-sm relative'>
        <div
          class='w-full h-full z-20 absolute top-0 left-0 opacity-0 hover:opacity-25 bg-gray-50' 
          @click='goToCurrentOtherSession(session.id)'>    
        </div>
        <div class='z-10 bottom-0 absolute opacity-90 uppercase text-white bg-gray-800 w-full text-center text-sm font-semibold py-1'>
          {{ sessionLocationsString(session) }}
        </div>
        <component :is='selectedLiveVideoPreviewPlayerComponent'
          :showing-content='session.content'
          class='w-full z-0' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {ChevronDownIcon, ChevronUpIcon} from 'vue-tabler-icons'

const LiveVideoPreviewContentVimeo = function() {
  return import('@/components/contents/LiveVideoPreviewContentVimeo')
}
const LiveVideoPreviewContentWecandeo = function() {
  return import('@/components/contents/LiveVideoPreviewContentWecandeo')
}

export default {
  name: 'SessionOtherSessions',
  components: {
    LiveVideoPreviewContentVimeo,
    LiveVideoPreviewContentWecandeo,
    ChevronDownIcon,
    ChevronUpIcon, 
  },
  data () {
    return {
      showOtherSessions: false,
    }
  },
  computed: {
    ...mapGetters('sessions', [
      'selectedSessionOtherSessions',
      'selectedSessionConfigDefaultPreviewVideoPlayer',
    ]),
    otherSessionsCount () {
      return this.selectedSessionOtherSessions.length
    },
    showIcon () {
      return (this.showOtherSessions) ? 'chevron-up-icon' : 'chevron-down-icon'
    },
    toggleButtonString () {
      return (this.showOtherSessions) ? 'hide' : 'show'
    },
    selectedLiveVideoPreviewPlayerComponent () {
      if (this.selectedSessionConfigDefaultPreviewVideoPlayer === 'vimeo') {
        return 'live-video-preview-content-vimeo'
      } else if (this.selectedSessionConfigDefaultPreviewVideoPlayer === 'wecandeo') {
        return 'live-video-preview-content-wecandeo'
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
    ]),
    goToCurrentOtherSession (sessionId) {
      let query = Object.assign({}, this.$route.query)
      this.getCurrentSessionId(sessionId).then((currentSessionId) => {
        if (currentSessionId) {
          // 다음 세션 있을 때
          query.session_id = currentSessionId
          this.$router.replace({name: 'Session', query: query}).catch(() => {})
        } else {
          // 다음 세션 없을 때
          this.$alert('There are no sessions in progress. Navigating to the programs page.', {
            type: 'info',
            confirmButtonText: 'Go to Programs'
          }).then(() => {
            this.$router.replace({name: 'Programs', query: query}).catch(() => {})
          }) 
        }
      })
    },
    sessionLocationsString (session) {
      return session.locations.map(location => location.split('||')[0]).join(', ')
    },
    toggleShowSessions () {
      this.showOtherSessions = !this.showOtherSessions
    }
  }
}
</script>

<template>
  <div class='border-b' style='word-break: keep-all;'>
    <session-details-by-topic 
      v-for='(subSession, index) in session.listOfSpeakers'
      :key='`subSession-${index}`'
      class='border-t'
      :subSession='subSession'/>
  </div>
</template>

<script>
import SessionDetailsByTopic from '@/components/sessions/SessionDetailsByTopic.vue'

export default {
  name: 'SessionDetailTopics',
  components: {
    SessionDetailsByTopic,
  },
  props: [
    'session',
  ],
}
</script>

